html, body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  background-color: #4f4f4f; /* Dark grey background for the whole page */
  color: white; /* Ensures text is readable */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  background-color: #333; /* Dark grey background */
  color: white;
  padding: 15px 0;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
}

.company-name {
  margin: 0;
  font-size: 1.5em;
}

.main-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.main-nav ul li {
  margin-left: 20px;
}

.main-nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.main-nav ul li a:hover {
  text-decoration: underline;
}

.introduction {
  position: relative;
  height: calc(100vh - 70px);
  background-image: url('../public/img/introduction_bg.jpg');
  background-repeat: no-repeat;
  background-position: center; /* Center the background image */
  background-size: auto;
}

.introduction::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8); /* Darker transparent overlay */
  z-index: 1; /* Ensure it's above the background image */
}

.intro-content {
  position: absolute;
  color: white;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 80%;
  z-index: 2; /* Ensure it's above the overlay */
}


@media (max-width: 768px) {
  .introduction {
    height: auto; /* Change height to auto for smaller screens */
  }

  .intro-content {
    position: relative; /* Change position to relative for better responsiveness */
    top: initial; /* Reset top and left properties */
    left: initial;
    transform: initial; /* Reset transform property */
    margin-top: 50px; /* Add margin-top to create space between the content and the top */
  }
}


.introduction h1 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.introduction p {
  font-size: 1.2em;
  line-height: 1.6;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  font-size: 1em;
  height: 100%;
}

.footer .footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer p {
  margin: 0;
  padding-right: 20px;
  padding-left: 20px;
}

.footer .footer-mail {
  display: inline-block;
  background-color: #555;
  border: none;
  padding: 5px 10px;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
  color: white;
  border-radius: 5px;
}

.footer .footer-mail:hover {
  background-color: #666;
  color: white;
  text-decoration: none;
}

.services-intro {
  display: flex;
  align-items: center;
  min-height: 74.6vh;
  padding: 50px 20px;
  background-color: #4f4f4f;
  color: white;
}

.contact-info {
  display: flex;
  align-items: center;
  min-height: 87vh;
}

.contact-text {
padding-top: 20px;
  margin-left:auto;
  margin-right:auto;
}

.contact-img {
margin-left:auto;
  margin-right:auto;
  padding-top: 40px;
padding-bottom: 40px;
}

.leistungen {
  padding: 50px;
  min-height: 87vh;
}

.services-text {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services-text h2 {
  margin-bottom: 20px; /* Reduce margin for smaller screens */
}

.services-text h3 {
  margin-bottom: 20px; /* Reduce margin for smaller screens */
}

.services-text p {
  margin-bottom: 40px; /* Reduce margin for smaller screens */
}

@media (max-width: 768px) {
  .services-text {
    max-width: 100%; /* Adjust max-width for smaller screens */
  }
}


.learn-more-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  max-width: 170px;
}

.learn-more-btn:hover {
  background-color: #0056b3;
}

.services-image img{
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  padding-top: 40px;
  max-width: 80%;
  
}

.service-section h2 {
  color: #FFF; /* Dark text for better readability */
  margin-top: 20px;
}

.service-section p {
  color: #EEE; /* Lighter text for the description */
  margin-bottom: 20px;
}

.separator-line {
  border: none;
  height: 1px;
  background: linear-gradient(to right, transparent, #bbb, transparent); /* Dashed line effect */
  margin: 20px 0;
}

.impressum-content {
  text-align: center;
  padding: 20px;
  line-height: 2;
  min-height: 87vh;
  max-width: 800px; /* Sets a maximum width for paragraphs */
  margin-left: auto; /* Centers the paragraph */
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.impressum-section h2 {
  font-weight: bold;

}

/* Mobile styles */
.header-content.mobile {
  flex-direction: column;
  align-items: center;
}

.mobile-nav {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.mobile-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-nav ul li {
  margin: 5px 0;
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 991px) {
  .header-content {
    padding: 0 40px;
  }

  .main-nav ul li {
    margin-left: 10px;
  }
}

/* Mobile styles */
.footer-content.mobile {
  flex-direction: column;
  align-items: center;
}

.footer-content.mobile p {
  margin-bottom: 10px;
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 991px) {
  .footer-content {
    padding: 0 40px;
  }
}

/* Mobile styles */
.intro-content.mobile {
  padding: 50px 20px;
}

.intro-content.mobile h1 {
  font-size: 1.8em;
}

.intro-content.mobile p {
  font-size: 1em;
}



/* Mobile menu styles */
.mobile-menu {
  position: relative;
  z-index: 3;
}

.mobile-menu-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.mobile-nav {
  position: absolute;
  top: 100%;
  left: -75%;
  right: 100%;
  width: 250%;
  background-color: #333;
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}


.mobile-nav.open {
  transform: scaleY(1);
}

.mobile-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-nav ul li {
  padding: 10px;
}

.mobile-nav ul li a {
  color: white;
  text-decoration: none;
}